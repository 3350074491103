import React from "react"
import { UniversalLink } from "../components/UniversalLink"
import { scrollToCTA } from "../pages"

export interface FAQ {
  question: string
  answer: string | JSX.Element
}

export const faqData: FAQ[] = [
  {
    question: `What are the XRPL Grants Terms & Conditions?`,
    answer: (
      <>
        <p>
          Terms and Conditions for XRPL Grant can be viewed{` `}
          <UniversalLink to="https://xrplgrants.org/terms">here</UniversalLink>.
          Grantees are expected to adhere to the{` `}
          <UniversalLink to="https://xrpl.org/code-of-conduct">
            Contributor Covenant Code of Conduct
          </UniversalLink>
          , and to support making the XRPL community welcoming and
          harassment-free.
        </p>

        <p>From the Contributor Covenant Code of Conduct:</p>
        <p>
          {` `}
          “Examples of behavior that contributes to creating a positive
          environment include:{` `}
        </p>
        <ul className="list-disc">
          <li>Using welcoming and inclusive language</li>
          <li>Being respectful of differing viewpoints and experiences</li>
          <li>Gracefully accepting constructive criticism</li>
          <li>Focusing on what is best for the community</li>
          <li>Showing empathy towards other community members</li>
        </ul>

        <p>
          Examples of behavior that does not contribute to creating a positive
          environment include:
        </p>
        <ul className="list-disc">
          <li>
            Using sexualized language or imagery and unwelcome sexual attention
            or advances
          </li>
          <li>
            Trolling, insulting/derogatory comments, and personal or political
            attacks{` `}
          </li>
          <li>Public or private harassment</li>
          <li>
            Publishing others’ private information, such as a physical or
            electronic address, without explicit permission
          </li>
          <li>
            Other conduct which could reasonably be considered inappropriate in
            a professional setting”
          </li>
        </ul>
      </>
    ),
  },
  {
    question: `What use cases are funded?`,
    answer: (
      <>
        <p>
          While XRPL Grants are open to all use cases, the program is currently
          prioritizing funding projects with the following use cases:
        </p>
        <ul className="list-disc">
          <li>Decentralized Finance (DeFi)</li>
          <li>Real World Asset (RWA) Tokenization</li>
          <li>Payments</li>
          <li>Infrastructure/Security</li>
          <li>Usability/Developer Tooling</li>
          <li>Artificial Intelligence (AI) </li>
        </ul>
      </>
    ),
  },
  {
    question: `How do judges evaluate an application?`,
    answer: (
      <>
        <p>
          The XRP Grants program seeks to fund software development projects and
          ventures leveraging the XRP Ledger (XRPL).
        </p>
        <p>
          The judging panel includes renowned subject matter experts in
          blockchain business development and business: CTOs, product managers,
          VCs and investors, and XRPL Community members, including past
          awardees. The judging committee can be viewed {` `}
          {` `}
          <UniversalLink to="https://xrplgrants.org/judges">here</UniversalLink>
          {` `}
          (subject to change).
        </p>
        <p>
          When evaluating applications, judges take a detailed and holistic look
          at the project to determine whether it has:
        </p>
        <ul className="list-disc">
          <li>
            A strong founding team with technical experience to execute the
            project
          </li>
          <li>
            A viable business use case that solves a problem or need in the
            industry
          </li>
          <li>A product with strong market opportunity</li>
          <li>Alignment with the capabilities of the XRPL</li>
          <li>
            Sustainability - does the team have a plan for how this project will
            be maintained long-term?{` `}
          </li>
        </ul>

        <p>
          Additionally, the XRPL Grants program reserves the right to decline
          any project. The XRPL Grants program team encourages program teams to
          encourage a positive environment and be supportive of other projects
          within the XRPL ecosystem. Reports of harassment, doxing, etc., that
          target other members of the community, members of the program team, or
          Judges will not be tolerated under any circumstances. All projects and
          teams are expected to adhere to the XRPL Grants Terms of Service and
          the XRPL Developers Contributor Code of Conduct. If the XRPL Grants
          program determines that you have violated these terms, especially in
          an egregious manner, your application to the program will not be
          accepted.
        </p>
        <p>
          Projects are scored on a Technical Assessment Rubric and a Business
          Assessment Rubric based on the following criteria:
        </p>
        <p>
          Technical Assessment: To determine the team’s technical strength and
          ability to build execute and maintain the project.
        </p>
        <ul className="list-disc">
          <li>
            Technical Team Strength: Does the team have the relevant technical
            skills and experience to build, execute, and maintain the project?
          </li>
          <li>
            Technical Design and XRPL Alignment: Does the project’s overall
            system design, architecture, technical approach, and alignment with
            the XRPL’s capabilities and integration requirements?
          </li>
          <li>
            Code Walkthrough and Review Quality: - The project shared original
            coding work (code may be a mix of original work with forked or open
            source components) that is generally readable and runnable - The
            project provided documentation with basic information about the
            project’s features and instructions for running the code - The team
            has demonstrated coding best practices, such as basic security
            considerations and authentication handling
          </li>
          <li>
            Product Roadmap and Milestones: The roadmap is realistic, and
            well-thought-out, and demonstrates an understanding of necessary
            steps, resources, and timelines necessary to complete the project,
            and the XRPL integration prioritized as an early milestone
          </li>
        </ul>
        <p>Business Assessment:</p>
        <ul className="list-disc">
          <li>
            Team Strength: the project has a strong founding team with the
            ability to execute the proposed roadmap
          </li>
          <li>
            Project Roadmap and Milestones: the team provides a clear,
            step-by-step roadmap and milestones for executing the project
          </li>
          <li>
            Market Opportunity: The project fills a needed market gap, and the
            team demonstrates an understanding of the competitive landscape
          </li>
          <li>
            Project Traction: the project has demonstrated growth in terms of
            user adoption, or revenue growth
          </li>
          <li>
            Financial Project Sustainability: the project team has demonstrated
            that they have a sustainable financial plan for long-term viability
          </li>
          <li>
            XRPL Utility and Onchain Activity Use Case Alignment: the project
            supports growth for the XRPL ecosystem, and has the potential to
            drive incremental onchain transactions, and/or provide key
            infrastructure and/or developer tooling for the XRPL ecosystem
          </li>
          <li>
            Commitment to the XRPL: the team is committed to
            building/integrating with the XRPL in the long-term
          </li>
        </ul>
      </>
    ),
  },
  {
    question: `Who can apply for a Grant?`,
    answer: (
      <>
        Applicants must be at least 18 years of age and not a citizen of, or
        located in, a country or territory on a{` `}
        <UniversalLink to="https://home.treasury.gov/policy-issues/office-of-foreign-assets-control-sanctions-programs-and-information">
          OFAC Sanctions List
        </UniversalLink>
        . Individuals should have technical development skills or expertise. The
        primary target for this grants program is software developers.
        <br />
        <br />
        Employees at Ripple are not eligible to apply for the XRPL Grants
        Program.
      </>
    ),
  },
  {
    question: `Why build on the XRP Ledger?`,
    answer: (
      <>
        With legal clarity, decade-long proven performance, and a dedicated
        community, the XRPL offers you the chance to build on rock solid
        foundations.
        <br />
        <br />
        The XRP Ledger has powered over 2.6 billion transactions, facilitating
        transactions worth over $1 trillion, a testament to its reliability and
        scalability.
        <br />
        <br />
        This is your chance to tap into ample XRP liquidity of 4.5M+ XRP wallets
        and become a part of an exciting network that includes 1000+ apps.
      </>
    ),
  },
  {
    question: `What personal information do I need to provide?`,
    answer: `You will be required to share full legal names and bios of all team members, as well as a copy of a government-issued ID (front and back). If considered for a finalist interview, we will complete additional compliance, legal, and sanctions screenings.`,
  },
  {
    question: `Do I have to receive a grant in order to build on the XRP Ledger?`,
    answer: (
      <>
        No, XRP Ledger is an open source, public, permissionless and
        decentralized blockchain that anyone can develop on. We encourage you to
        build and develop your project using the technical resources on{` `}
        <UniversalLink to="https://home.treasury.gov/policy-issues/office-of-foreign-assets-control-sanctions-programs-and-information">
          XRPL.org
        </UniversalLink>
        {` `}and in the wider XRP community. The{` `}
        <UniversalLink to="http://xrpldevs.org/">
          XRP Ledger Developers Discord
        </UniversalLink>
        {` `} is also a great place to connect and engage with others in the
        XRPL community. If you are new to the XRPL, the{` `}
        <UniversalLink to="https://learn.xrpl.org/">
          Learning Platform
        </UniversalLink>
        {` `}is a great place to start and learn the basics of crypto,
        blockchain and coding on the XRPL.
      </>
    ),
  },
  {
    question: `What is the application period?`,
    answer: (
      <>
        <p>
          XRPL Grants has moved to a rolling application process starting in
          Spring 2024. click{` `}
          <UniversalLink to="https://submit.xrplgrants.org/submit">
            here
          </UniversalLink>
          {` `} to view current funding opportunities.
        </p>
      </>
    ),
  },
  {
    question: `What information will I need to provide on the application?`,
    answer: (
      <>
        <p>
          To apply you must use the official application form during a
          designated application wave. On the application form you will need to
          provide:
        </p>
        <ul className="list-disc">
          <li>Project Prototype</li>
          <li>Proof of Project Code (ex. Github Repository)</li>
          <li>2-min Project Demo</li>
          <li>A detailed description of your project</li>
          <li>Product and development Roadmap</li>
          <li>Timeline of 3 to 12 Months</li>
          <li>Plan for Financial Growth/Sustainability</li>
          <li>Clear XRPL Use-case reasoning and integration plan</li>
          <li>
            At least one experienced software developer on the core project team
          </li>
        </ul>
      </>
    ),
  },
  {
    question: `Does the Github repository need to be public?`,
    answer: `No, but we require that your submitted Github repository can be shareable with application reviewers at least temporarily, in order to make the review process smooth and as fast as possible a list of handles to share your Github repo with is included on the application form.`,
  },
  {
    question: `What is the range of award amounts?`,
    answer: (
      <>
        <p>Awards range between $10,000 and $200,000 per project.</p>
        <p>
          Awards of $100,000 and greater are typically for developers who have
          already successfully completed a comparable project.
        </p>
      </>
    ),
  },
  {
    question: `How should I determine what size of an award to apply for?`,
    answer: `The award amount you request should be determined by the scope of work that you wish to complete using your grant. Your application must contain milestones/scope of work and a budget breakdown detailing how you intend to spend any awarded funds. Your budget should include estimates for conversion rates and taxes.`,
  },
  {
    question: `Can I apply for a follow-on grant?`,
    answer: (
      <>
        <p>
          While the opportunity for additional funding from either the Grants
          program or other Ripple funding programs is a possibility, it is not a
          guarantee. Part of the application process is sharing your plan for
          project sustainability, i.e. how will you support the project past
          funding from the XRPL Grants program. If you are a previous awardee
          and interested in applying for follow-on funding, please contact the
          program team to discuss.
        </p>
        <p>
          The Grants program will likely not fund a single project more than
          twice.
        </p>
      </>
    ),
  },
  {
    question: `Are there restrictions on what I can apply awarded funds toward?`,
    answer: `We are looking to support technical project development and related expenses. Projects requesting funding for general infrastructure (including validators) and overhead may not be funded. Your budget may include salaries, legal fees, external audits, etc.. You are responsible for figuring out tax rates and any estimated conversion and including them in your budget estimations. (See below FAQ for additional information on Tax Implications.)`,
  },
  {
    question: `Can I submit an Application in a language other than English?`,
    answer: `At this time, all applications must be submitted in English and interviews will be conducted in English.`,
  },
  {
    question: `Can I apply with more than one project?`,
    answer: `No. We will only consider a single project per organization/team/project lead. We recommend putting effort into submitting your strongest proposal, instead of submitting multiple proposals.`,
  },
  {
    question: `What types of projects will be considered for funding?`,
    answer: (
      <>
        <p>
          {` `}
          All projects must add value to the XRPL ecosystem and involve a
          technical component. The XRPL Grants program considers applications
          for innovative use cases that solve real-world problems utilizing
          blockchain technology. However, even if your project use case is not
          explicitly listed below, it may still be considered for funding.
          Examples of the types of projects that may be funded, include, but are
          not limited to:
        </p>

        <p>Tokenization</p>
        <ul className="list-disc">
          <li>Regulatory Compliant Real World Assets (RWA)</li>
          <li>
            Proof of Existence (e.g. Certificates, Degrees, Legal Ownership)
          </li>
        </ul>

        <p>Infrastructure & Security</p>
        <ul className="list-disc">
          <li>Decentralized Compliance & Security</li>
        </ul>

        <p>Interoperability</p>
        <ul className="list-disc">
          <li>Cross-chain messaging</li>
          <li>
            Implementation of blockchain interoperability protocol/technology
            with another blockchain
          </li>
        </ul>

        <p>Data API</p>
        <ul className="list-disc">
          <li>Data Visualization Tools</li>
        </ul>

        <p>Developer Tooling/Usability</p>
        <ul className="list-disc">
          <li>Client Libraries/SDKs</li>
        </ul>

        <p>Financial Use Cases</p>
        <ul className="list-disc">
          <li>Financial Equity / Inclusion</li>
          <li>
            Decentralized Finance (DeFi) - Lending & Borrowing, DEX/AMM
            interfaces and integrations{` `}
          </li>
          <li>Payments</li>
          <li>Digital Remittances</li>
          <li>Merchant Integration</li>
          <li>Insurance</li>
        </ul>

        <p>Sustainability/Social Impact</p>
        <ul className="list-disc">
          <li>Regenerative Finance (ReFi)</li>
        </ul>
      </>
    ),
  },
  {
    question: `How mature does a project need to be in order to apply for funding?`,
    answer: `All projects must have a prototype or proof-of-concept that we can review on GitHub. The minimum is at least a few days of work (e.g. a  proof of concept created over the course of a weekend or during a hackathon). XRPL Grants will not fund projects that are just an idea or do not show sufficient initial work. Additionally, the reviewers are looking for overall effort, not just base code.`,
  },
  {
    question: `What are the tax implications of receiving funding?`,
    answer: `Often this money is treated as taxable income, but recipients are responsible for determining the tax implications of receiving an award based on their respective countries’ tax laws and compliance requirements. You should factor in and include the estimated taxes in your submitted budget document.`,
  },
  {
    question: `Do I have to have a company in order to receive funding?`,
    answer: `No. We will fund individuals and small teams, as well as companies. See above question regarding tax implications. If a team is awarded, funds are paid to one individual (the project lead) and the project lead will be responsible for disbursing and managing the funds for the project.`,
  },
  {
    question: `Do I need to have prior experience building on XRP Ledger to apply for funding?`,
    answer: (
      <>
        <p>
          Previous XRPL experience is not required, but highly encouraged. In
          order to receive funding judges will be evaluating your genuine
          interest, understanding of the XRPL and technical ability to execute
          the project by your core team. If you are new to blockchain and the
          XRPL we recommend you take the free courses on the{` `}
          <UniversalLink to="https://learn.xrpl.org/">
            Learning Platform
          </UniversalLink>
          {` `}and/or review the documentation at{` `}
          <UniversalLink to="https://xrpl.org/">XRPL.org</UniversalLink>.
        </p>
      </>
    ),
  },
  {
    question: `How do I start building on XRPL?`,
    answer: (
      <>
        There are many ways to interact with the XRP Ledger, including client
        libraries (SDKs) in JavaScript, Python, and Java. There are also APIs
        available over HTTP (JSON-RPC) and WebSockets. For a tutorial on how to
        send money, look{` `}
        <UniversalLink to="https://xrpl.org/get-started.html">
          here
        </UniversalLink>
        . For reference docs, see{` `}
        <UniversalLink to="https://xrpl.org">XRPL.org</UniversalLink>.{` `}We
        also recommend the free{` `}
        <UniversalLink to="https://learn.xrpl.org/course/intro-to-the-xrpl/">
          Intro to the XRPL
        </UniversalLink>
        {` `}and{` `}
        <UniversalLink to="https://learn.xrpl.org/course/code-with-the-xrpl/">
          Code with the XRPL
        </UniversalLink>
        {` `}courses.
      </>
    ),
  },
  {
    question: `I don’t know if XRPL Grants is the right program for me.`,
    answer: (
      <>
        There are other funding mechanisms to build on the XRPL. See the{` `}
        <UniversalLink to="https://xrpl.org/developer-funding.html">
          XRPL Funding Page
        </UniversalLink>
        {` `}to see if Grants is the proper funding mechanism for your project.
      </>
    ),
  },
  {
    question: `How can I learn more?`,
    answer: (
      <p>
        If you have any other questions, please contact us at{` `}
        <UniversalLink to="mailto:info@xrplgrants.org">
          info@xrplgrants.org
        </UniversalLink>
        .
      </p>
    ),
  },
]
